<script lang="ts" setup>
const { t } = useI18n()

const isOpen = defineModel<boolean>({
  default: false,
})
</script>

<template>
  <DialogRoot
    :open="isOpen"
  >
    <DialogPortal>
      <DialogOverlay class="fixed inset-0 z-30 bg-gray-700 opacity-50" />
      <DialogContent
        class="fixed left-1/2 top-1/2 z-100 translate-x-[-50%] translate-y-[-50%] rounded-xl bg-white px-2 pb-2 pt-4 focus:outline-none"
        @interact-outside="isOpen = false"
      >
        <div class="flex flex-col gap-4">
          <DialogTitle class="flex items-center justify-between px-4">
            <p class="text-center text-4.5 font-semibold">
              {{ t('account_verification.brand_info.modal.title') }}
            </p>
            <button type="button" class="i-lucide:x h-5 min-h-5 min-w-5 w-5 text-gray-500 transition-colors hover:text-gray-400" @click="isOpen = false" />
          </DialogTitle>
          <DialogDescription class="i-custom:b2b-dashboard-online-shop-explain h-[calc(275/1063*80vw)] w-[80vw] rounded-3 xl:h-[275px] xl:w-[1063px]" />
        </div>
      </DialogContent>
    </DialogPortal>
  </DialogRoot>
</template>
