import type { ComputedRef, MaybeRef } from 'vue'
import { useQuery, useQueryClient } from '@tanstack/vue-query'
import { useOpenApiClient } from './useAreaTreeOpenApiClient'

export function useGetAllAreas(includeChildren: MaybeRef<boolean> = true, enabled: MaybeRef<boolean> | ComputedRef<boolean> = true) {
  const queryClient = useQueryClient()
  const { apiInstance } = useOpenApiClient()
  const {
    isLoading,
    isFetching,
    isError,
    isSuccess,
    data,
    error,
    refetch,
    suspense,
  } = useQuery({
    queryKey: ['getAllAreas'],
    queryFn: () => apiInstance.value.areaUserControllerFindUserAreas({ includeChildren: unref(includeChildren) }),
    enabled,
  })

  watch(data, (areas) => {
    areas?.forEach((area) => {
      queryClient.setQueryData(['getArea', area.id], area)
    })
  })

  const areas = computed(() => {
    return data.value ?? []
  })

  const formattedAreas = computed(() => {
    return areas.value.map(area => ({
      name: [area.address?.city, area.name].filter(s => s).join(', '),
      value: area.id,
    })).sort((a, b) => a.name.localeCompare(b.name))
  })

  const areasNameAndUUID = computed(() => {
    return areas.value.map((area) => {
      const locality = area.address ? area.address.city : ''
      return {
        value: area.id,
        name: [locality, area.name]
          .filter(v => v !== '')
          .join(', '),
      }
    })
  })

  return {
    isLoading,
    isFetching,
    isError,
    isSuccess,
    data,
    error,
    refetch,
    suspense,
    areas,
    formattedAreas,
    areasNameAndUUID,
    suspenseGetAreas: suspense,
  }
}
