<script lang="ts" setup generic="DataType">
import type { Row } from '@tanstack/vue-table'

defineOptions({
  name: 'TableBody',
})
const props = defineProps<{
  rows: Row<DataType>[]
  selectable?: boolean
}>()
</script>

<template>
  <tbody class="bg-white">
    <BaseTableBodyRow :rows="props.rows" :selectable="props.selectable">
      <template #customCell="{ row }">
        <slot name="customCell" :row="row" />
      </template>
      <template #additionalColumns="{ row }">
        <slot name="additionalColumns" :row="row" />
      </template>
      <template #actions="{ row }">
        <slot name="actions" :row="row" />
      </template>
    </BaseTableBodyRow>
  </tbody>
</template>
