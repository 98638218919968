<script lang="ts" setup>
const emits = defineEmits<{
  onClick: []
}>()

const { t } = useI18n()
</script>

<template>
  <BaseGradientBorder :radius="12" :border-size="1">
    <div class="w-full flex flex-col gap-2 rounded-3 bg-gray-50 p-1 sm:flex-row">
      <div class="i-custom:mhp-pp-transition-logos h-10 min-h-10 min-w-35 w-35 hidden sm:block" />
      <button class="group w-full flex flex-col justify-between gap-4 overflow-hidden b-1 b-gray-100 rounded-2 bg-white px-4 py-1.75 text-left sm:flex-row" @click="emits('onClick')">
        <div class="w-full flex flex-col gap-2 overflow-hidden text-gray-900 sm:flex-row sm:items-center sm:text-nowrap">
          <p class="text-4 font-600 leading-6">
            {{ t('account_verification.brand_info.banner.title') }}
          </p>
          <p class="text-3.5 font-400 leading-5 sm:w-full sm:overflow-hidden sm:text-ellipsis">
            {{ t('account_verification.brand_info.banner.details') }}
          </p>
        </div>
        <div class="flex items-center justify-end gap-2 text-nowrap sm:justify-start">
          <p>
            {{ t('account_verification.brand_info.banner.button') }}
          </p>
          <div class="i-custom:arrow-narrow-right h-5 min-h-5 min-w-5 w-5 transition duration-300 group-hover:translate-x-1" />
        </div>
      </button>
    </div>
  </BaseGradientBorder>
</template>
