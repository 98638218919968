<script setup lang="ts">
defineOptions({
  name: 'GradientBorder',
})

const { borderSize = 1, radius = 24, widthFitType = 'full', heightFitType = 'fit' } = defineProps<{

  /**
   * border size in pixels
   */
  borderSize?: number

  /**
   * radius size in pixels
   */
  radius?: number
  /**
   * width fitting full/fit
   */
  widthFitType?: Fitting
  /**
   * width fitting full/fit
   */
  heightFitType?: Fitting
}>()
type Fitting = 'fit' | 'full'
type Color = 'primary'
const innerBorderStyle = computed(() => {
  return `border-radius: ${radius}px;`
})

const outerRadiusStyle = computed(() => {
  return `border-radius: ${radius + borderSize}px; padding: ${borderSize}px;`
})

// Only one color supported now
const colorClassesMap = new Map<Color, string>([
  ['primary', 'from-gradientBorder-green from-20% via-50% to-80% via-gradientBorder-gray to-gradientBorder-purple'],
])

const widthFittingClassMap = new Map<Fitting, string>([
  ['fit', 'w-fit'],
  ['full', 'w-full'],
])

const heightFittingClassMap = new Map<Fitting, string>([
  ['fit', 'h-fit'],
  ['full', 'h-full'],
])
</script>

<template>
  <div
    class="content-center justify-items-center bg-gradient-to-br"
    :class="[
      colorClassesMap.get('primary'),
      widthFittingClassMap.get(widthFitType),
      heightFittingClassMap.get(heightFitType),
    ]"
    :style="outerRadiusStyle"
  >
    <div class="h-full w-full content-center justify-items-center bg-white" :style="innerBorderStyle">
      <slot />
    </div>
  </div>
</template>
